import Content from "../common/Content";
import Footer from "../common/Footer";
import Header from "../common/Header";
import DataTable from "react-data-table-component";
import { API } from "../../helper/Api";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";

export default function Employee() {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    employeeList();
  }, [page]);

  const employeeList = () => {
    setPending(true);

    API.getEmployee({ page }) 
      .then((datas) => {
        const items = datas.ApiDocument.Response.Employees.flatMap(
          (Employees) => Employees
        );
        const totalRecords = datas.ApiDocument.TotalRecords;
        //const itemIterate = datas.ApiDocument.Response.Styles.flatMap(mainStyle => mainStyle.StoreDescription);
        setItems(items);
        setTotalRecords(totalRecords);
        setPending(false);
      })
      .catch((err) => console.log(err));
  };
// const employeeSearch = (e) => {
//           const searchKey = e.target.value;
//           if(searchKey){
//           API.getEmployeeSearchData({searchKey})
//           .then((datas) => {
//             const items = datas.ApiDocument.Response.Styles.flatMap(
//               (style) => style
//             );
//             const totalRecords = datas.ApiDocument.TotalRecords;
//             setItems(items);
//             setTotalRecords(totalRecords);
//             setPending(false);
//           })
//           .catch((err) => console.log(err));
//         }else{
//           catalogList();

//         }

// }
  const nextButton = document.getElementById("pagination-next-page");

  const handleRowClicked = (row) => {
    setPage(row); 
  };
const dataSearch =() =>{
  alert("Hi");
}
  const columns = [
    // { name: 'ID', selector: row => row.StyleNo, sortable: true },
    {
      name: "First Name",
      selector: (row) => row.FirstName,
      sortable: true,
    },
    { name: "Last Name", selector: (row) => row.LastName, sortable: true },
    { name: "Email", selector: (row) => row.EMail1, sortable: true },
    { name: "Login", selector: (row) => row.LoginName, sortable: true },

    {
      name: "Action",
      cell: (row) => (
        <a href="#" value={row.StyleNo}>
          Edit
        </a>
      ),
      sortable: true,
    },
    // Add more columns as needed
  ];

    return (
      <>
            <Header />
            <div class="container-fluid page-body-wrapper">
          <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
              <li class="nav-item nav-category"></li>              
              <li class="nav-item">
                <a class="nav-link" href="/">
                  <span class="icon-bg"><i class="mdi mdi-home menu-icon"></i></span>
                  <span class="menu-title">Dashboard</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="employee">
                  <span class="icon-bg"><i class="mdi mdi-account-circle menu-icon"></i></span>
                  <span class="menu-title">Employees</span>
                </a>
              </li>               
              <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="catalog-list" aria-expanded="false" aria-controls="ui-basic">
                  <span class="icon-bg"><i class="mdi mdi-crosshairs-gps menu-icon"></i></span>
                  <span class="menu-title">Products</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="web-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Web Orders</span>
                </a>
              </li>                                          
              <li class="nav-item">
                <a class="nav-link" href="purchase-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Purchase Orders</span>
                </a>
              </li>  
              
              {/* <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-settings menu-icon"></i>
                    <span class="menu-title">Settings</span>
                  </a>
                </div>
              </li>

              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-logout menu-icon"></i>
                    <span class="menu-title">Log Out</span></a>
                </div>
              </li> */}
            </ul>
          </nav>


            <div class="main-panel">
        <div class="content-wrapper">
        <div class="page-header">
              <h3 class="page-title">Employee List</h3>
            </div>
            <div>


             <DataTable
                columns={columns}
                data={items}
                pagination
                paginationServer={true}
                // onChangeRowsPerPage={handleRowClicked}
                onChangePage={handleRowClicked}
                paginationTotalRows={totalRecords}
                paginationPerPage={10}
                progressPending={pending}

              />
            </div>
        </div>
      </div>


      </div>  

      <Footer />
      </>
      
    );

}
