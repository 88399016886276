document.addEventListener('DOMContentLoaded', function() {
	'use strict';
  
	const formControls = document.querySelectorAll('.form-control');
  
	formControls.forEach(function(input) {
	  input.addEventListener('input', function() {
		const field = this.closest('.form-group');
		if (this.value) {
		  field.classList.add('field--not-empty');
		} else {
		  field.classList.remove('field--not-empty');
		}
	  });
	});
  });