export class API{
//   static getData(){
//     fetch('https://jsonplaceholder.typicode.com/photos')
//     .then((res) => {
//         return res.json();
//     })
//     .then((data) => {
//         return data
//     });
// }

static addCatalog(props){
  const data = new URLSearchParams({
    type:props.type,
    productCode:props.productCode,
    clu: props.clu,
    vlu: props.vlu,
    description: props.description,
    primaryVendor: props.primaryVendor,
    dept: props.dept,
    deptClass: props.deptClass,
    taxGroupCode: props.taxGroupCode,
    basePrice: props.basePrice,
    minQty: props.minQty,
    orderCost: props.orderCost,
    upc: props.upc,
  });

  fetch('https://tw.ebramha.com/api/addCatalog.php', {
    method: 'POST',
    body: data
  })
    .then(response => response.json())
    .catch(error => console.error('Error:', error));

  // return fetch ('https://dealer.tvsgreen.com/tw/addCatalog.php', {
  //   method: 'POST', // or 'PUT', depending on the API
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify(data) // Convert data to JSON string
  // })
  // .then(response => response.json()) // Parse JSON response
  // .then(result => result(result))

}


static getEmployee(page) {
  let url = ''
  if(page.page === 0){
    url = 'https://tw.ebramha.com/api/employee.php'
  }else{
    url = 'https://tw.ebramha.com/api/employee.php?page='+page.page
  }
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((resp)=> resp.json());
}


static getData() {
  // let url = ''
  // if(page.page === 0){
  //   url = 'https://tw.ebramha.com/api/catalog.php'
  // }else{
  //   url = 'https://tw.ebramha.com/api/catalog.php?page='+page.page
  // }
    return fetch("https://tw.ebramha.com/api/catalog.php", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((resp)=> resp.json());
}
static getUser(user){
  const data = new URLSearchParams({
    name: user.name,
    pwd: user.pwd,
  });
  
  fetch('https://tw.ebramha.com/api/user.php', {
    method: 'POST',
    body: data
  })
    .then(response => response.json())
    .catch(error => console.error('Error:', error));
}

static getCustomer(page) {
  let url = ''
  if(page.page === 0){
    url = 'https://tw.ebramha.com/api/customer.php'
  }else{
    url = 'https://tw.ebramha.com/api/customer.php?page='+page.page
  }

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((resp)=> resp.json());
}

static getWebOrderData(page) {
  let url = ''
  if(page.page === 0){
    url = 'https://tw.ebramha.com/api/salesOrders.php'
  }else{
    url = 'https://tw.ebramha.com/api/salesOrders.php?page='+page.page
  }

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((resp)=> resp.json());
}

static getSpecificWebOrderData(props){
  return fetch(`https://tw.ebramha.com/api/salesOrderView.php?id=${props.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then((resp)=> resp.json()); 
}

static getPurchaseOrder(page) {
  let url = ''
  if(page.page === 0){
    url = 'https://tw.ebramha.com/api/purchaseOrders.php'
  }else{
    url = 'https://tw.ebramha.com/api/purchaseOrders.php?page='+page.page
  }

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((resp)=> resp.json());
}


static getSearchData(props) {
  return fetch(`https://tw.ebramha.com/api/getSearchData.php?searchKey=${props.searchKey}`)
  .then((response) => response.json())
}

static getSpecificData(props){
  return fetch(`https://tw.ebramha.com/api/editCatalog.php?id=${props.id}`)
  .then((response) => response.json())
}  

static updateCatalogPrice(props){

  const data = new URLSearchParams({
    "itemnumber": props.itemnumber,
    "price": JSON.stringify(props.prices),  // Serialize prices object as JSON string
  });

  return fetch(`https://tw.ebramha.com/api/priceUpdate.php`,
    {    
      method: 'POST',
      body: data
    })
    .then(response => response.json())
}


}


