import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Function to parse a 24-hour time string (HH:mm:ss format) - not needed in this case
const parseTime = (timeStr) => {
  const [hours, minutes, seconds] = timeStr.split(':').map(Number);
  const now = new Date();
  now.setHours(hours, minutes, seconds, 0); // Set hours, minutes, seconds, and milliseconds
  return now;
};

const SessionTimeoutModal = () => {
  const [countdown, setCountdown] = useState(1);  // Countdown for 120 seconds
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const redirects=()=>{
    navigate('/login');
  }
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const sessionTimeout = 1 * 60 * 1000;  // 3 minutes in milliseconds
  const countdownDuration = 120;  // Countdown starts from 120 seconds

  // Static start time: current time minus 3 minutes
  const currentTime = Date.now(); // Get current time in milliseconds
  const startTime = currentTime + sessionTimeout; // Subtract 3 minutes from current time

  

  useEffect(() => {
    console.log("Session Timeout Modal Mounted");

    // Use the startTime as calculated above
    const startDate = startTime;
    console.log(`Parsed startTime: ${new Date(startDate)}`);

    // Start interval to check the session status every second
    const intervalId = setInterval(() => {
      const currentTime = Date.now();  // Get current time in milliseconds
      const elapsedTime = currentTime - startDate;  // Calculate elapsed time

      // Log the current time and elapsed time difference
     // console.log(`Start Time: ${new Date(startDate.getTime())}`);
      console.log(`Current Time: ${new Date(currentTime)}`);
      console.log(`Elapsed Time: ${elapsedTime} ms`);

      // Check if 3 minutes have passed since the start time
      if (elapsedTime >= sessionTimeout && !isSessionExpired) {
        console.log("Session expired. Showing modal and starting countdown.");
        setShowModal(true);
        setCountdown(countdownDuration);  // Start countdown from 120 seconds
        setIsSessionExpired(true);  // Stop further polling for session expiry
      }

      // If countdown is active, update it
      if (showModal && countdown > 0) {
        console.log(`Countdown: ${countdown} seconds remaining`);
        setCountdown(prev => prev - 1);
      }

      // If countdown reaches 0, stop and redirect to login
      if (countdown === 0) {
        navigate('/login');
        console.log("Countdown reached 0. Redirecting to login.");
        clearInterval(intervalId); // Clear the interval
      //  window.location.href = '/login';  // Redirect to login page
      }

    }, 1000); // Check every 1 second

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      console.log("Interval cleared.");
    };
  }, [startTime, countdown, showModal, isSessionExpired]);

  return (
    <>
      {showModal && (
        <div style={{
          position: 'fixed', top: 0, left: 0, right: 0, bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center',
          alignItems: 'center', zIndex: 1000
        }}>
          <div style={{
            backgroundColor: 'white', padding: '20px', borderRadius: '8px',
            width: '400px', textAlign: 'center'
          }}>
            <h5>Your session is about to expire!</h5>
            <p>Time remaining: {countdown} seconds</p>
            <div style={{
              height: '8px', backgroundColor: '#4caf50', borderRadius: '4px', margin: '10px 0'
            }}>
              <div style={{
                width: `${(countdown / countdownDuration) * 100}%`, height: '100%',
                backgroundColor: '#f44336', borderRadius: '4px'
              }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button
                style={{ padding: '10px 20px', backgroundColor: '#6c757d', color: 'white', border: 'none', borderRadius: '4px' }}
                onClick={redirects}
              >
                No, End Session
              </button>
              <button
                style={{ padding: '10px 20px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '4px' }}
                onClick={() => setShowModal(false)}
              >
                Yes, Continue Session
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SessionTimeoutModal;
