export default function Content ({data: RenderData}) {
    return (
      
      <div class="container-fluid page-body-wrapper">
          <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
            <li class="nav-item nav-category"></li>              
              <li class="nav-item">
                <a class="nav-link" href="/">
                  <span class="icon-bg"><i class="mdi mdi-home menu-icon"></i></span>
                  <span class="menu-title">Dashboard</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="employee">
                  <span class="icon-bg"><i class="mdi mdi-account-circle menu-icon"></i></span>
                  <span class="menu-title">Employees</span>
                </a>
              </li>               
              <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="catalog-list" aria-expanded="false" aria-controls="ui-basic">
                  <span class="icon-bg"><i class="mdi mdi-crosshairs-gps menu-icon"></i></span>
                  <span class="menu-title">Products</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="web-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Web Orders</span>
                </a>
              </li>                                          
              <li class="nav-item">
                <a class="nav-link" href="purchase-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Purchase Orders</span>
                </a>
              </li>               
              {/* <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="/#" class="nav-link"><i class="mdi mdi-settings menu-icon"></i>
                    <span class="menu-title">Settings</span>
                  </a>
                </div>
              </li>

              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="/#" class="nav-link"><i class="mdi mdi-logout menu-icon"></i>
                    <span class="menu-title">Log Out</span></a>
                </div>
              </li> */}
            </ul>
          </nav>

        <RenderData />

        </div>  
    )
}