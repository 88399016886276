import React from 'react'
import Content from "../common/Content";
import Header from "../common/Header";
import { API } from "../../helper/Api";
import { useSearchParams,useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner'




export default function SalesOrderView() {

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [pending, setPending] = useState(true);
  const [status, setStatus] = useState("");
  const [salesOrderNo, setSalesOrderNo] = useState("");
  const [salesOrderDateTime, setSalesOrderDateTime] = useState("");
  const [billtoCustomerName, setBilltoCustomerName] = useState("");
  const [billtoCustomerEmail, setBilltoCustomerEmail] = useState("");
  const [billtoCustomerAddress1, setBilltoCustomerAddress1] = useState("");
  const [shiptoCustomerName, setShiptoCustomerName] = useState("");
  const [shiptoCustomerEmail, setShiptoCustomerEmail] = useState("");
  const [shiptoCustomerAddress1, setShiptoCustomerAddress1] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [totalAmountWithoutTax, setTotalAmountWithoutTax] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
  const [totalAmountWithTax, setTotalAmountWithTax] = useState(0);
  const [accountNumber, setAccountNumber] = useState(0);
  const [receipt, setReceipt] = useState(0);
  const [paymentCardType, setPaymentCardType] = useState("");
  const [paymentMethodDescription, setPaymentMethodDescription] = useState("");
  const [paymentMethodCode, setPaymentMethodCode] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [shipmentStatus, setShipmentStatus] = useState("");
  const [fillLocation, setFillLocation] = useState(0);
  const [shipMemoNo, setShipMemoNo] = useState(0);
  const [shipmentItems, setShipmentItems] = useState([]);
  const [activeView, setActiveView] = useState('order');

  const handleNavClick = (view) => {
    setActiveView(view);
  };

useEffect(() => {
    API.getSpecificWebOrderData({id})
    .then(datas => {
      const order = datas.ApiDocument.Response.SalesOrders[0];
      setStatus(order.StatusName);
      setSalesOrderNo(order.SalesOrderNo);
      setSalesOrderDateTime(order.SalesOrderDateTime);
      setTotalAmountWithoutTax(order.TotalAmountWithoutTax);
      setTotalTaxAmount(order.TotalTaxAmount);
      setTotalDiscountAmount(order.TotalDiscountAmount);
      setTotalAmountWithTax(order.TotalAmountWithTax);
      setBilltoCustomerName(order.BillInfo['FirstName'] + " " +  order.BillInfo['LastName']);
      setBilltoCustomerEmail(order.BillInfo['Email']);
      setBilltoCustomerAddress1(order.BillInfo['Address1']);

      setShiptoCustomerName(order.ShipInfo['FirstName'] + " " +  order.ShipInfo['LastName']);
      setShiptoCustomerEmail(order.ShipInfo['Email']);
      setShiptoCustomerAddress1(order.ShipInfo['Address1']); 

      setReceipt(order.Payments[0]['ReceiptIdentifier']);  
      setAccountNumber(order.Payments[0]['AccountNumber']);  
      setPaymentCardType(order.Payments[0]['PaymentCardType']);  
      setPaymentMethodDescription(order.Payments[0]['PaymentMethodDescription']);  
      setPaymentMethodCode(order.Payments[0]['PaymentMethodCode']);  
      setPaymentAmount(order.Payments[0]['PaymentAmount']);  

      setShipmentStatus(order.ShipMemos[0]['Status']);  
      setFillLocation(order.ShipMemos[0]['FillLocation']);  
      setShipMemoNo(order.ShipMemos[0]['ShipMemoNo']);  

      setOrderItems(order.Items);
      setShipmentItems(order.ShipMemos[0].Items);
      setPending(false);
    })
    .catch((err) => console.log(err))
}, [id]);



  return (
    <>
                <Header />
                <div class="container-fluid page-body-wrapper">
          <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
            <li class="nav-item nav-category"></li>              
              <li class="nav-item">
                <a class="nav-link" href="/">
                  <span class="icon-bg"><i class="mdi mdi-home menu-icon"></i></span>
                  <span class="menu-title">Dashboard</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="employee">
                  <span class="icon-bg"><i class="mdi mdi-account-circle menu-icon"></i></span>
                  <span class="menu-title">Employees</span>
                </a>
              </li>               
              <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="catalog-list" aria-expanded="false" aria-controls="ui-basic">
                  <span class="icon-bg"><i class="mdi mdi-crosshairs-gps menu-icon"></i></span>
                  <span class="menu-title">Products</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="web-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Web Orders</span>
                </a>
              </li>                                          
              <li class="nav-item">
                <a class="nav-link" href="purchase-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Purchase Orders</span>
                </a>
              </li>  
              
              {/* <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-settings menu-icon"></i>
                    <span class="menu-title">Settings</span>
                  </a>
                </div>
              </li>

              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-logout menu-icon"></i>
                    <span class="menu-title">Log Out</span></a>
                </div>
              </li> */}
            </ul>
          </nav>


            <div class="main-panel">
        <div class="content-wrapper">

        <div class="app-container">
      {/* <nav class="nav-buttons">
        <button class="nav-btn active" data-view="order">Order Details</button>
        <button class="nav-btn" data-view="shipment">Shipment</button>
      </nav> */}

      <nav className="nav-buttons">
        {/* Order Details Button */}
        <button  className={`nav-btn ${activeView === 'order' ? 'active' : ''}`}   onClick={() => handleNavClick('order')}>
          Order Details
        </button>

        {/* Shipment Button */}
        <button  className={`nav-btn ${activeView === 'shipment' ? 'active' : ''}`}  onClick={() => handleNavClick('shipment')}>
          Shipment
        </button>
      </nav>      

      <div className="container">
        <div class="order_content">
      {pending ? (
        <div className="loader">
          <RotatingLines
            visible={true}
            height="96"
            width="96"
            strokeColor="grey"
            strokeWidth="2"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          {/* Order View */}
          {activeView === 'order' && (
          <div id="order-view" className="view active">
            <div className="status-header">
              <div className="header-content">
                <h1 className="order-title">Order #{salesOrderNo}</h1>
                <p className="order-date">Placed on Jan 15, 2024</p>
              </div>
              <span className={`status-badge success`}>{status}</span>
            </div>

            <div className="info-grid">
              <div className="info-card">
                <h3>Bill to Customer</h3>
                <div className="info-content">
                  <p className="customer-name">Name : {billtoCustomerName}</p>
                  <p>Email : {billtoCustomerEmail}</p>
                  <p>Address : {billtoCustomerAddress1}</p>
                  <p>Phone : </p>
                </div>
              </div>

              <div className="info-card">
                <h3>Ship to Customer</h3>
                <div className="info-content">
                  <p className="customer-name">Name : {shiptoCustomerName}</p>
                  <p>Email : {shiptoCustomerEmail}</p>
                  <p>Address : {shiptoCustomerAddress1}</p>
                  <p>Phone : </p>
                </div>
              </div>

              {/* <div className="info-card">
                <h3>Payment Details</h3>
                <div className="info-content">
                  <p className="payment-method">
                    <span className="card-icon">💳</span>
                    Visa ending in 4242
                  </p>
                  <p className="payment-status success">Payment Completed</p>
                  <p>Transaction ID: TXN123456789</p>
                </div>
              </div> */}
            </div>

            <div className="sales_order_view_card">

            <div class="table-responsive items-table">
                  <table class="table table-bordered">
                  <thead>
                                <tr>
                                  <th>Product</th>
                                  <th>PLU</th>
                                  <th>CLU</th>
                                  <th>UPC</th>
                                  <th>FillLocation</th>
                                  <th>OrderQty</th>
                                  <th>QtyDue</th>
                                  <th>QtyCancelled</th>
                                </tr>
                              </thead>
                              <tbody>
                              {orderItems.map((value) =>
                            
                                <tr>
                                  <td>
                                    <div className="product-info">
                                      {/* <img src="https://via.placeholder.com/40" alt="Product A" className="product-image" /> */}
                                      <div>
                                        <p className="product-name">{value.ItemDetails['ItemDescription']}</p>
                                        <p className="product-sku">Code: {value.ItemDetails['StyleNo']}</p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{value.ItemDetails['PLU']}</td>
                                  <td>{value.ItemDetails['CLU']}</td>
                                  <td>{value.ItemDetails['UPC']}</td>
                                  <td>{value.FillLocation}</td>
                                  <td>{value.OrderQty}</td>
                                  <td>{value.QtyDue}</td>
                                  <td>{value.QtyCancelled}</td>
                                </tr>
                            )}
                              </tbody>
                  </table>
                </div>


                <div className="info-grid">

              <div className="info-card">
                <h3>Payment Details</h3>
                <div className="info-content">
                  <p>Receipt :  {receipt}</p>
                  <p>AccountNumber :  {accountNumber}</p>
                  <p>PaymentMethodDescription :  {paymentMethodDescription}</p>
                  <p>PaymentCardType : {paymentCardType}</p>
                  <p>PaymentAmount :  ${paymentAmount}</p>
                  {/* <p className="payment-method">
                    <span className="card-icon">💳</span>
                    Visa ending in 4242
                  </p> */}
                  {/* <p className="payment-status success">Payment Completed</p>
                  <p>Transaction ID: TXN123456789</p> */}
                </div>
              </div>

              <div className="summary-card">
                <div className="summary-row">
                  <span>Subtotal</span>
                  <span>${totalAmountWithoutTax}</span>
                </div>
                <div className="summary-row">
                  <span>Discount</span>
                  <span>${totalDiscountAmount}</span>
                </div>
                <div className="summary-row">
                  <span>Tax</span>
                  <span>${totalTaxAmount}</span>
                </div>
                <div className="summary-row total">
                  <span>Total</span>
                  <span>${totalAmountWithTax}</span>
                </div>
              </div>                
            </div>

            </div>
          </div>
)}         
 {/* Shipment View */}
 {activeView === 'shipment' && (
          <div>
          <div id="shipment-view" >
            <div className="status-header">
              <div className="header-content">
                <h1 className="order-title">Shipment Status</h1>
                <div className="tracking-info">
                  <span className="tracking-label">Ship Number:</span>
                  <span className="tracking-number">{shipMemoNo}</span>
                </div>
              </div>
              <div className="shipment-status">
                <div className="status-indicator">
                  <div className="status-dot"></div>
                  <div className="status-line"></div>
                </div>
                <span className="status-badge in-transit">
                  <span className="status-icon">📦</span>
                  {shipmentStatus}
                </span>
              </div>
            </div>

            <div className="shipment-details">
              {/* <div className="info-grid">
                <div className="info-card">
                  <h3>Delivery Address</h3>
                  <div className="info-content">
                    <p className="customer-name">John Doe</p>
                    <p>456 Shipping Lane</p>
                    <p>Customer City, State 12345</p>
                    <p>United States</p>
                  </div>
                </div>

                <div className="info-card">
                  <h3>Delivery Details</h3>
                  <div className="info-content">
                    <p><strong>Carrier:</strong> UPS Express</p>
                    <p><strong>Estimated Delivery:</strong> Jan 20, 2024</p>
                    <p><strong>Service Type:</strong> Priority Shipping</p>
                  </div>
                </div>
              </div> */}


<div class="table-responsive items-table">
                  <table class="table table-bordered">
                  <thead>
                                <tr>
                                  <th>Line No</th>
                                  <th>Product Code</th>
                                  <th>Tracking Id</th>
                                  <th>QTY</th>
                                  <th>Status</th>
                                  <th>Shipped Date</th>
                                  <th>Shipping Method</th>
                                  <th>Shipping Cost</th>

                                </tr>
                              </thead>
                              <tbody>
                              {shipmentItems.map((value) =>
                                <tr>
                                  <td>{value.LineNo}</td>
                                  <td>
                                    <div className="product-info">
                                      {/* <img src="https://via.placeholder.com/40" alt="Product A" className="product-image" /> */}
                                      <div>
                                        <p className="product-name">{value.ItemIdentifier}</p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{value.Cartons[0]['TrackingId']}</td>
                                  <td>{value.Cartons[0]['Qty']}</td>
                                  <td>{value.Status}</td>
                                  <td>{value.Cartons[0]['ShippedDate']}</td>
                                  <td>{value.Cartons[0]['ShippingMethod']}</td>
                                  <td>${value.Cartons[0]['ActualShippingCost']}</td>


                                </tr>
                            )}
                              </tbody>
                  </table>
                </div>

              {/* <div className="timeline-container">
                <h3>Tracking History</h3>
                <div className="timeline">
                  <div className="timeline-item current">
                    <div className="timeline-point"></div>
                    <div className="timeline-content">
                      <div className="timeline-header">
                        <h4>Package in Transit</h4>
                        <span className="timeline-date">Jan 18, 2024 - 10:30 AM</span>
                      </div>
                      <p>Package is in transit to the destination</p>
                      <p className="timeline-location">Memphis, TN</p>
                    </div>
                  </div>
                  <div className="timeline-item completed">
                    <div className="timeline-point"></div>
                    <div className="timeline-content">
                      <div className="timeline-header">
                        <h4>Departed Shipping Facility</h4>
                        <span className="timeline-date">Jan 17, 2024 - 8:15 PM</span>
                      </div>
                      <p>Package has left the carrier facility</p>
                      <p className="timeline-location">Louisville, KY</p>
                    </div>
                  </div>
                  <div className="timeline-item completed">
                    <div className="timeline-point"></div>
                    <div className="timeline-content">
                      <div className="timeline-header">
                        <h4>Order Processed</h4>
                        <span className="timeline-date">Jan 16, 2024 - 9:00 AM</span>
                      </div>
                      <p>Order has been processed and label created</p>
                      <p className="timeline-location">Chicago, IL</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          </div>
        )}


          {/* Invoice View */}
          <div id="invoice-view" className="view">
            {/* Invoice content goes here */}
          </div>
        </>
      )}
      </div>
    </div>
    </div>


        </div>
      </div>


      </div>                
    </>
  )
}
