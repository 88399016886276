// import React from 'react'

// export default function SalesOrders() {
//   return (
//     <div>SalesOrders</div>
//   )
// }


import Content from "../common/Content";
import Footer from "../common/Footer";
import Header from "../common/Header";
import DataTable from "react-data-table-component";
import { API } from "../../helper/Api";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";

export default function SalesOrders() {
  const [photos, setPhotos] = useState([]);
  const [items, setItems] = useState([]);
  const [mainData, setmainData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pending, setPending] = useState(true);
  const [saleDate, setsaleDate] = useState("");

  useEffect(() => {
    webOrderList();
  }, [page]);

  const webOrderList = () => {
    setPending(true);

    API.getWebOrderData({ page })
      .then((datas) => {
        const items = datas.ApiDocument.Response.SalesOrders.flatMap(
          (SalesOrders) => SalesOrders
        );
        const totalRecords = datas.ApiDocument.TotalRecords;
        //const itemIterate = datas.ApiDocument.Response.Styles.flatMap(mainStyle => mainStyle.StoreDescription);
        setItems(items);
        setTotalRecords(totalRecords);
        setPending(false);


      })
      .catch((err) => console.log(err));
  };

  // const usFormattedDate = new Date(isoDateString).toLocaleDateString('en-US');

  const nextButton = document.getElementById("pagination-next-page");

  const handleRowClicked = (row) => {
    // console.log("row", row);
    // console.log("row_id",totalRows);
    setPage(row);
    
  };
const dataSearch =() =>{
  alert("Hi");
}
  const columns = [
    // { name: 'ID', selector: row => row.StyleNo, sortable: true },
    {
      name: "Order#",
      selector: (row) => row.SalesOrderNo,
      sortable: true,
    },
    { name: "Customer", selector: (row) => row.SellInfo.Email, sortable: true },
    { name: "Order Date", selector: (row) => row.SalesOrderDateTime, sortable: true },
    { name: "Promise Date", selector: (row) => row.PromiseDate, sortable: true },
    { name: "Sales Location", selector: (row) => row.SellFromLocation, sortable: true },
    { name: "Fill Location", selector: (row) => row.FillLocation, sortable: true },
    { name: "Status", selector: (row) => row.StatusName, sortable: true },
    { name: "Order Amount", selector: (row) => row.TotalAmountWithTax, sortable: true },
    {
      name: "Action",
      cell: (row) => (
        <a href={`web-orders-view?id=${row.SalesOrderNo}`} value={row.SalesOrderNo}>
          Edit
        </a>
      ),
      sortable: true,
    },
    // {
    //   name: "Price",
    //   selector: (row) => row.Items[0].BasePrice,
    //   sortable: true,
    // },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <a href={`catalog-price-update?id=${row.StyleNo}`} value={row.StyleNo}>
    //       Edit
    //     </a>
    //   ),
    //   sortable: true,
    // },
    // Add more columns as needed
  ];

  // const RenderData = () => {
    return (
      <>
            <Header />
            <div class="container-fluid page-body-wrapper">
          <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
            <li class="nav-item nav-category"></li>              
              <li class="nav-item">
                <a class="nav-link" href="/">
                  <span class="icon-bg"><i class="mdi mdi-home menu-icon"></i></span>
                  <span class="menu-title">Dashboard</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="employee">
                  <span class="icon-bg"><i class="mdi mdi-account-circle menu-icon"></i></span>
                  <span class="menu-title">Employees</span>
                </a>
              </li>               
              <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="catalog-list" aria-expanded="false" aria-controls="ui-basic">
                  <span class="icon-bg"><i class="mdi mdi-crosshairs-gps menu-icon"></i></span>
                  <span class="menu-title">Products</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="web-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Web Orders</span>
                </a>
              </li>                                          
              <li class="nav-item">
                <a class="nav-link" href="purchase-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Purchase Orders</span>
                </a>
              </li>  
              
              {/* <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-settings menu-icon"></i>
                    <span class="menu-title">Settings</span>
                  </a>
                </div>
              </li>

              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-logout menu-icon"></i>
                    <span class="menu-title">Log Out</span></a>
                </div>
              </li> */}
            </ul>
          </nav>


            <div class="main-panel">
        <div class="content-wrapper">
          {/* {pending ? (
            <div className="loader">
              <RotatingLines
                visible={true}
                height="96"
                width="96"
                strokeColor="grey"
                strokeWidth="2"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />{" "}
            </div>
          ) : ( */}
            <div>
            {/* <form class="example" action="/action_page.php"> */}
              {/* <input type="text" placeholder="Search.." name="search2" />
              <button type="submit" onClick={dataSearch}><i class="fa fa-search"></i></button> */}
            {/* </form>  */}

             <DataTable
                title="Web Orders"
                columns={columns}
                data={items}
                pagination
                paginationServer={true}
                // onChangeRowsPerPage={handleRowClicked}
                onChangePage={handleRowClicked}
                paginationTotalRows={totalRecords}
                paginationPerPage={10}
                progressPending={pending}

              />
            </div>
          {/* )} */}
        </div>
      </div>


      </div>  

      <Footer />
      </>
      
    );
  // };

  // return (
  //   <>
  //     {/* {photos.map((data) => (
  //           <h1>{data.id}</h1>
  //       ))} */}
  //     <Header />
  //     <Content data={RenderData} />
  //     <Footer />
  //   </>
  // );
}
