import Content from "../common/Content";
import Footer from "../common/Footer";
import Header from "../common/Header";
import DataTable from "react-data-table-component";
import { API } from "../../helper/Api";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";
import $ from 'jquery';


export default function CatalogList() {
  const [photos, setPhotos] = useState([]);
  const [items, setItems] = useState([]);
  const [mainData, setmainData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    catalogLists();
  }, []);

  useEffect(() => {
    // Initialize DataTable after data is fetched
    if (items.length > 0) {
      $('#myTable1').DataTable({
        paging: true,
        searching: true,
        ordering: true,
        info: true,
        lengthChange: true,
        pageLength: 25,
        responsive: true,
        autoWidth: false,
        destroy: true, // Make sure to destroy the previous instance
        // columnDefs: [
        //   {
        //     targets: 0, // Target the first column (index 0)
        //     width: '500px' // Set the column width to 200px
        //   },]
        // drawCallback: function(settings) {
        //   setTotalRecords(settings.json.recordsTotal);
        // }
      });
    }

    return () => {
      // Cleanup the DataTable when component unmounts
      if ($.fn.dataTable.isDataTable('#myTable1')) {
        $('#myTable1').DataTable().destroy();
      }
    };
  }, [items]);

  const catalogLists = () => {
    setPending(true);

    API.getData() 
      .then((datas) => {
        const items = datas.ApiDocument.Response.Styles.flatMap(
          (style) => style
        );
        console.log(items);
        console.log("catalog list", items);
        const totalRecords = datas.ApiDocument.TotalRecords;
        setItems(items);
       // setTotalRecords(totalRecords);
        setPending(false);
      })
      .catch((err) => console.log(err));
  };


  return (
    <>
      <Header />
      <div className="container-fluid page-body-wrapper">
      <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
              <li class="nav-item nav-category"></li>              
              <li class="nav-item">
                <a class="nav-link" href="/">
                  <span class="icon-bg"><i class="mdi mdi-home menu-icon"></i></span>
                  <span class="menu-title">Dashboard</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="employee">
                  <span class="icon-bg"><i class="mdi mdi-account-circle menu-icon"></i></span>
                  <span class="menu-title">Employees</span>
                </a>
              </li>               
              <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="catalog-list" aria-expanded="false" aria-controls="ui-basic">
                  <span class="icon-bg"><i class="mdi mdi-crosshairs-gps menu-icon"></i></span>
                  <span class="menu-title">Products</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="web-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Web Orders</span>
                </a>
              </li>                                          
              <li class="nav-item">
                <a class="nav-link" href="purchase-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Purchase Orders</span>
                </a>
              </li>  
              
              {/* <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-settings menu-icon"></i>
                    <span class="menu-title">Settings</span>
                  </a>
                </div>
              </li>

              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-logout menu-icon"></i>
                    <span class="menu-title">Log Out</span></a>
                </div>
              </li> */}
            </ul>
          </nav>


        <div className="main-panel">
          <div className="content-wrapper">
              <div className="button_list">
                  <div className="additembutton">
                    <button type="submit" className="btn btn-primary mr-2">
                      <a href="/add-new-catalog">ADD NEW ITEM</a>
                    </button>
                  </div>
                </div>

            <div class="Card_contend">
            {pending ? (
              <div className="loader">
                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  strokeColor="grey"
                  strokeWidth="2"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                />
              </div>
            ) : (
              <div>
                {!pending ? ( 
                  // <div style={{backgroundColor: "white",padding: "15px",marginTop: "10px"}}>
                  <table id="myTable1" className="row-border">           
                      <thead>
                        <tr>
                        <th style={{fontWeight:"bold",color: "#665d5d"}}>CLU</th>
                        <th style={{fontWeight:"bold",color: "#665d5d"}}>DESCRIPTION</th>
                        <th style={{fontWeight:"bold",color: "#665d5d"}}>COST</th>
                        <th style={{fontWeight:"bold",color: "#665d5d"}}>PRICE</th>
                        <th style={{fontWeight:"bold",color: "#665d5d"}}>VENDOR</th>
                        {/* <th style={{fontWeight:"bold",color: "#665d5d"}}>NSO</th> */}
                        <th style={{fontWeight:"bold",color: "#665d5d"}}>UPC</th>
                        </tr>
                      </thead>
                        <tbody>
                          {items.map((item, index) => (
                            <tr key={index}>
                              <td>{item.Items[0].CLU ? <a href={`catalog-price-update?id=${item.StyleNo}`} value={item.StyleNo}>{item.Items[0].CLU}</a> : ''}</td>
                              <td>{item.StoreDescription ? item.StoreDescription : ''}</td>
                              <td>${item.OrderCost ? item.OrderCost.toFixed(2) : '0.00'}</td>
                              <td>${item.Items[0].BasePrice ? item.Items[0].BasePrice.toFixed(2) : '0.00'}</td>
                              <td>{item.PrimaryVendorIdentifier ? item.PrimaryVendorIdentifier : ''}</td> 
                              {/* <td></td>  */}
                              <td>{item.Items[0].UPC ? item.Items[0].UPC : '-'}</td>
                           
                            </tr>
                          ))}
                        </tbody>
                      </table>
                // </div>
                ): null} 
              </div>
            )}
            
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>

  );
}