import Content from "../common/Content"
import Footer from "../common/Footer"
import Header from "../common/Header"
import { API } from "../../helper/Api";
import { useSearchParams,useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner'



export default function CatalogPriceUpdate(){
    
    // let id  = useSearchParams(); 
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id')
    const [postValue, setPosts] = useState([]);
    const [stores, setStores] = useState([]);
    const [title , setTitle] = useState("");
    const [itemnumber , setItemnumber] = useState("");
    const [price , setPrice] = useState("");
    const [plu , setPLU] = useState("");
    const [clu , setCLU] = useState("");
    const [upc , setUPC] = useState("");
    const [newPrice , setnewPrice] = useState("");
    const navigate = useNavigate();
    const [pending, setPending] = useState(true);
    const [store, setStore] = useState("");
    const [deptName, setDeptName] = useState("");
    const [deptClassName, setDeptClassName] = useState("");
    const [productCode, setProductCode] = useState(0);
    const [prices, setPrices] = useState([]);
    


    // const [updateTitle , setupdateTitle] = useState([]);

    useEffect(() => {
        API.getSpecificData({id})
        .then(datas => {
          const items = datas.ApiDocument.Response.Styles.flatMap(style => style);
console.log(datas.ApiDocument.Response);
          setPosts(items);
          setPending(false);
        })
        .catch((err) => console.log(err))
    }, [id]);

    useEffect(() => {
      if(postValue[0] != undefined){
        setStores(postValue[0].Items[0].Prices);
        setTitle(postValue[0].StoreDescription);
        setItemnumber(postValue[0].Items[0].CLU);
        setPLU(postValue[0].Items[0].PLU );
        setCLU(postValue[0].Items[0].CLU );
        setUPC(postValue[0].Items[0].UPC );
        setPrice(postValue[0].Items[0].BasePrice);
        setDeptName(postValue[0].DCSS.DeptName);
        setDeptClassName(postValue[0].DCSS.ClassName);
        setProductCode(postValue[0].StyleNo);
        setPrices(postValue[0].Items[0].Prices);
      }
    }, [postValue[0]]);   


    const updateCallBack = (result) =>{
        if(result.Id) {
            toast.success('Price Updated', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                });
            setTimeout(() => {
              window.location.reload();

                // navigate("/catalog-list");
              }, 3000);            
            //return navigate("/catalog-list");
        } 
    }
  const handleInputChange = (index, event) => {
    const newPrice = event.target.value;
    const updatedPrices = [...prices]; 
    updatedPrices[index].newPrice = newPrice; 
    setPrices(updatedPrices); 
  };

    const updatePrice = (e) => {
       e.preventDefault();
      //  setStore(e.target.form.store.value);
      //  const oldprice = e.target.form.oldprice.value;
      //  const itemnumber = e.target.form.itemnumber.value;
      //  const newprice = e.target.form.newprice.value;
       API.updateCatalogPrice({ store,itemnumber,prices })
         .then(result => updateCallBack(result))
         .catch(error => console.log('error', error));
      
    }
    const   storeChange = (e) => {
      setStore(e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text);
      const selectStore = e.target.options[e.target.selectedIndex].text;
      setPrice(e.target.value);
      const selectedStore = stores.find((member) => member.PriceLevel === selectStore);
      if (selectedStore) {
        setPrice(selectedStore.Price);
      }
      // this.setState({
      //     selectedOption: target.value,
      // });
    }



    // const RenderData = () => {
        return (
            <>
<ToastContainer
position="top-center"
autoClose={500}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>

<Header />
            <div class="container-fluid page-body-wrapper">
          <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
            <li class="nav-item nav-category"></li>              
              <li class="nav-item">
                <a class="nav-link" href="/">
                  <span class="icon-bg"><i class="mdi mdi-home menu-icon"></i></span>
                  <span class="menu-title">Dashboard</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="employee">
                  <span class="icon-bg"><i class="mdi mdi-account-circle menu-icon"></i></span>
                  <span class="menu-title">Employees</span>
                </a>
              </li>               
              <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="catalog-list" aria-expanded="false" aria-controls="ui-basic">
                  <span class="icon-bg"><i class="mdi mdi-crosshairs-gps menu-icon"></i></span>
                  <span class="menu-title">Products</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="web-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Web Orders</span>
                </a>
              </li>                                          
              <li class="nav-item">
                <a class="nav-link" href="purchase-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Purchase Orders</span>
                </a>
              </li>                 
              {/* <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-settings menu-icon"></i>
                    <span class="menu-title">Settings</span>
                  </a>
                </div>
              </li>

              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-logout menu-icon"></i>
                    <span class="menu-title">Log Out</span></a>
                </div>
              </li> */}
            </ul>
          </nav>

       <div class="main-panel">
          <div class="content-wrapper">
            <div class="page-header">
              <h3 class="page-title">Product Update Page</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/catalog-list" class="btn btn-primary">Back</a></li>
                  {/* <li class="breadcrumb-item active" aria-current="page">Catalog Price Update</li> */}
                </ol>
              </nav>
            </div>
              <div class="col-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                   { pending ? <div className="loader">   
   <RotatingLines
    visible={true}
    height="96"
    width="96"
    strokeColor="grey"
    strokeWidth="2"
    animationDuration="0.75"
    ariaLabel="rotating-lines-loading"
    wrapperStyle={{}}
    wrapperClass=""
    /> </div>  : 
    <div className="productDetails">
    <h4 class="card-title">Product Name : {title}</h4>
    {/* <p class="card-description">PLU : {plu} </p> */}

    <div className="info-grid">
              <div className="info-product-card">
                <div className="info-content">
                <p>Product Code : {productCode}</p>
                <p>PLU : {plu}</p>
                <p>CLU : {clu}</p>
                <p>UPC : {upc}</p>
                </div>
              </div>

              <div className="info-product-card">
                <div className="info-content">
                <p>Department : {deptName}</p>
                  <p>Class : {deptClassName}</p>
                  <p>Base Price : {price}</p>
                </div>
              </div>
            </div>

    <form class="forms-sample">

    <div class="table-responsive prices-table">
      <h5>Price List</h5>
                  <table class="table table-bordered">
                  <thead>
                                <tr>
                                  <th>Store Name</th>
                                  <th>Old Price</th>
                                  <th>New Price</th>
                                </tr>
                              </thead>
                              <tbody>
                              {prices.map((value, index) => (
                                    <tr key={index}>
                                      <td>{value.PriceLevel}</td>
                                      <td>{value.Price}</td>
                                      <td className="newprice">
                                        <input type="text" className="form-control" onChange={(e) => handleInputChange(index, e)} />
                                      </td>
                                    </tr>
                              ))}

                              </tbody>
                  </table>
        </div>


        <div class="row">
            {/* <div class="col-6 grid-margin">
            <h4>Old Price</h4>
                  {prices.map((value) =>
                    <div class="form-group">
                      <label for="exampleInputName1">{value.PriceLevel}</label>
                      <input type="text" class="form-control" id="itemnumber" placeholder="Name" value={value.Price}/>
                    </div>
                  )}
            </div>
            <div class="col-6 grid-margin">
            <h4>New Price</h4>
                  {prices.map((value) =>
                    <div class="form-group">
                      <label for="exampleInputName1">{value.PriceLevel}</label>
                      <input type="text" class="form-control" id="itemnumber" placeholder="Name" value={value.Price}/>
                    </div>
                  )}
            </div> */}
            
        </div>

      <button type="submit" class="btn btn-primary mr-2" onClick={updatePrice} >Update</button>
      <button class="btn btn-light">Cancel</button>
    </form>
    </div>
    }
 
                  </div>
                </div>
              </div>
            </div>
          </div> 
          
          </div>  

<Footer />
                     </>
        )
    }
    // return (
    //     <>
    //             <Header />
    //             <Content  data={RenderData}/>
    //             <Footer />
    //     </>
    // )
//}