import Content from "../common/Content"
import Footer from "../common/Footer"
import Header from "../common/Header"
import { API } from "../../helper/Api";
import { useSearchParams,useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner'




export default function AddNewCatalog(){

// Basic Information
const [type, setType] = useState("SingleItem");
const [clu, setClu] = useState("");
// const [plu, setPlu] = useState("");
const [vlu, setVlu] = useState("");
const [description, setDescription] = useState("");
const [primaryVendor, setPrimaryVendor] = useState("");
const [productCode, setProductCode] = useState("");
// const [brandName, setBrandName] = useState("");

const selectType=(e)=>{setType(e.target.value);}


// Classification
const [dept, setDept] = useState(0);
// const [altdept, setAltDept] = useState(0);
const [deptClass, setClass] = useState(0);
const [taxGroupCode, setTaxGroupCode] = useState(0);

const selectDept=(e)=>{setDept(e.target.value);}
const selectClass=(e)=>{setClass(e.target.value);}

// Basic Pricing & Inventory
const [basePrice, setBasePrice] = useState(0);
const [minQty, setMinQty] = useState(0);
const [orderCost, setOrderCost] = useState(0);
// const [dateAvailable, setDateAvailable] = useState(0);

// // Dimensions
// const [height, setHeight] = useState("");
// const [width, setWidth] = useState("");
// const [length, setLength] = useState("");
// const [weight, setWeight] = useState("");

// UPC Information
// const [vendorUpc, setVendorUpc] = useState("");
const [upc, setUPC] = useState("");

// // Class Information
// const [altClass, setAltClass] = useState("");
const [subClass1, setSubClass1] = useState("");
const [subClass2, setSubClass2] = useState("");
// const [altSubClass1, setAltSubClass1] = useState("");
// const [altSubClass2, setAltSubClass2] = useState("");

// // Attribute Information
const [attributeSet1, setAttributeSet1] = useState("");
const [attributeSet2, setAttributeSet2] = useState("");
const [attributeSet3, setAttributeSet3] = useState("");
const [attribute1, setAttribute1] = useState("");
const [attribute2, setAttribute2] = useState("");
const [attribute3, setAttribute3] = useState("");

// // Price Information
// const [eligibleForDiscount, setEligibleForDiscount] = useState("");
// const [shipFromVendor, setShipFromVendor] = useState("");
// const [sellMethod, setSellMethod] = useState("");
// const [discontinued, setDiscontinued] = useState("false");

// const selectEligibleForDiscount=(e)=>{setEligibleForDiscount(e.target.value);}
// const selectShipFromVendor=(e)=>{setShipFromVendor(e.target.value);}
// const selectDiscontinued=(e)=>{setDiscontinued(e.target.value);}

// // Price Level
// const [priceLevel2, setPriceLevel2] = useState("");
// const [priceLevel3, setPriceLevel3] = useState("");
// const [priceLevel4, setPriceLevel4] = useState("");
// const [priceLevel5, setPriceLevel5] = useState("");
// const [priceLevel6, setPriceLevel6] = useState("");
// const [priceLevel7, setPriceLevel7] = useState("");
// const [priceLevel8, setPriceLevel8] = useState("");
// const [priceLevel9, setPriceLevel9] = useState("");

// // price
// const [price2, setPrice2] = useState("");
// const [price3, setPrice3] = useState("");
// const [price4, setPrice4] = useState("");
// const [price5, setPrice5] = useState("");
// const [price6, setPrice6] = useState("");
// const [price7, setPrice7] = useState("");
// const [price8, setPrice8] = useState("");
// const [price9, setPrice9] = useState("");

// // Vendor Information
// const [customText1, setCustomText1] = useState("");
// const [customLongText1, setCustomLongText1] = useState("");
// const [customLongText2, setCustomLongText2] = useState("");
// const [customLongText3, setCustomLongText3] = useState("");

// // Custom Flag
// const [customFlag1, setCustomFlag1] = useState("false");
// const [customFlag2, setCustomFlag2] = useState("false");
// const [customFlag3, setCustomFlag3] = useState("false");
// const [customFlag4, setCustomFlag4] = useState("false");
// const [customFlag5, setCustomFlag5] = useState("false");
// const [customFlag6, setCustomFlag6] = useState("false");

// const selectCustomFlag1=(e)=>{setCustomFlag1(e.target.value);}
// const selectCustomFlag2=(e)=>{setCustomFlag2(e.target.value);}
// const selectCustomFlag3=(e)=>{setCustomFlag3(e.target.value);}
// const selectCustomFlag4=(e)=>{setCustomFlag4(e.target.value);}
// const selectCustomFlag5=(e)=>{setCustomFlag5(e.target.value);}
// const selectCustomFlag6=(e)=>{setCustomFlag6(e.target.value);}


// // Lookup
// const [customLookup1, setCustomLookup1] = useState("");
// const [customLookup2, setCustomLookup2] = useState("");
// const [customLookup3, setCustomLookup3] = useState("");
// const [customLookup4, setCustomLookup4] = useState("");
// const [customLookup5, setCustomLookup5] = useState("");
// const [customLookup6, setCustomLookup6] = useState("");
// const [customLookup7, setCustomLookup7] = useState("");
// const [customLookup8, setCustomLookup8] = useState("");

// // Multi Select
// const [customMultiselects1, setCustomMultiselects1] = useState("");
// const [customMultiselects2, setCustomMultiselects2] = useState("");
// const [customMultiselects3, setCustomMultiselects3] = useState("");



const navigate = useNavigate();



const newCatalog =(e) =>{
    e.preventDefault();
    API.addCatalog(
      {
        type,
        clu,
        vlu,
        description,
        primaryVendor,
        productCode,
        dept,
        deptClass,
        taxGroupCode,
        basePrice,
        minQty,
        orderCost,
        upc,
      }
    );
    toast.success('Added Catalog', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
      });
  setTimeout(() => {
      navigate("/add-new-catalog");
    }, 3000); 

    // .catch(error => console.log('error', error));
}
const addCallBack = (result) => {
  if(result) {
    toast.success('Added Catalog', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        });
    setTimeout(() => {
        navigate("/add-new-catalog");
      }, 3000);            
}}




    return <>
    <ToastContainer
position="top-center"
autoClose={500}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
    
    <Header />            
    <div class="container-fluid page-body-wrapper">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
            <li class="nav-item nav-category"></li>              
              <li class="nav-item">
                <a class="nav-link" href="/">
                  <span class="icon-bg"><i class="mdi mdi-home menu-icon"></i></span>
                  <span class="menu-title">Dashboard</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="employee">
                  <span class="icon-bg"><i class="mdi mdi-account-circle menu-icon"></i></span>
                  <span class="menu-title">Employees</span>
                </a>
              </li>               
              <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="catalog-list" aria-expanded="false" aria-controls="ui-basic">
                  <span class="icon-bg"><i class="mdi mdi-crosshairs-gps menu-icon"></i></span>
                  <span class="menu-title">Products</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="web-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Web Orders</span>
                </a>
              </li>                                          
              <li class="nav-item">
                <a class="nav-link" href="purchase-orders">
                  <span class="icon-bg"><i class="mdi mdi-database menu-icon"></i></span>
                  <span class="menu-title">Purchase Orders</span>
                </a>
              </li>                
              {/* <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-settings menu-icon"></i>
                    <span class="menu-title">Settings</span>
                  </a>
                </div>
              </li>

              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-logout menu-icon"></i>
                    <span class="menu-title">Log Out</span></a>
                </div>
              </li> */}
            </ul>
          </nav>

          <div class="main-panel">
          <div class="content-wrapper">
          {/*  <div class="page-header">
              <h3 class="page-title">Add New Catalog</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/catalog-list" class="btn btn-primary">Back</a></li>
                </ol>
              </nav>
            </div>
              <div class="col-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                  
    <div className="productDetails">
    <form class="forms-sample">
        <div class="row">
            <div class="col-6 grid-margin">
            <div class="form-group">
        <label for="store">Department</label>
        <select class="form-control" id="store" onChange={selectDept}>
        <option value="">Select Department</option>
        <option value="001">ARTS & CRAFTS</option>
        <option value="002">BOOKS</option>
        <option value="004">ACTIVE PLAY & SKILL TOYS</option>
        <option value="006">GAMES & PUZZLES</option>
        <option value="007">DOLLS</option>
        <option value="009">CONSTRUCTION & BUILDING</option>
        <option value="011">IMPULSE</option>
        <option value="012">PLUSH</option>
        <option value="013">IMAGINATIVE & PRETEND PLAY</option>
        <option value="014">SCIENCE</option>
        <option value="015">TRANSIT</option>
        <option value="017">TRADING CARDS</option>
        <option value="018">FASHION</option>
        <option value="019">DEVELOPMENTAL & EDUCATIONAL</option>
        <option value="021">FOOD</option>
        <option value="029">SERVICES</option>
        <option value="030">SUPPLIES</option>
        <option value="999">NEON ELEPHANT</option>

        </select>

      </div>
      <div class="form-group">
        <label for="primary_vendor">Primary Vendor</label>
        <input type="text" class="form-control" id="primary_vendor" placeholder="" onChange={(e)=>setPrimaryVendor(e.target.value)}/>
      </div> 

      <div class="form-group">
        <label for="taxgroupcode">TaxGroupCode</label>
        <input type="text" class="form-control" id="taxgroupcode" placeholder="" onChange={(e)=>setTaxGroupCode(e.target.value)}/>
      </div>             
            </div>
            <div class="col-6 grid-margin">
            <div class="form-group">
        <label for="dept_class_code">Department Class Code</label>
         <select class="form-control" id="store" onChange={selectDeptClassCode}>
        <option value="">Select Department</option>
        <option value="001">ACTIVITY KITS</option>
        <option value="002">ART SUPPLIES</option>
        <option value="003">FASHION & JEWELRY KITS</option>
        <option value="021">ACTIVITY BOOKS</option>
        <option value="022">BOARD & CLOTH BOOKS</option>
        <option value="023">CHAPTER & SERIES BOOKS</option>
        <option value="024">LEARN TO READ BOOKS</option>
        <option value="025">WORKBOOKS</option>
        <option value="026">HARDCOVER</option>
        <option value="027">PAPERBACK</option>
        <option value="028">BABY & INDESTRUCTIBLES</option>
        <option value="041">BUBBLES & CHALK</option>
        <option value="042">INDOOR PLAY</option>
        <option value="043">LAWN & GARDEN</option>
        <option value="044">OUTDOOR PLAY</option>
        <option value="045">POOL, WATER & SAND</option>
        <option value="046">RIDE-ONS</option>
        <option value="047">SKILL TOYS</option>
        <option value="048">SPORTS</option>
        <option value="061">BRAINTEASERS</option>
        <option value="062">CARD GAMES</option>
        <option value="063">ELECTRONIC </option>
        <option value="064">FAMILY GAMES </option>
        <option value="065">PUZZLES </option>
        <option value="066">TABLETOP GAMES </option>
        <option value="067">HANDHELD GAMES </option>
        <option value="261">LEARNING AIDS </option>
        <option value="081">BABY DOLLS & ACCESSORIES</option>
        <option value="082">COLLECTIBLES</option>
        <option value="083">FASHION DOLLS & ACCESSORIES</option>
        <option value="084">DOLL HOUSES, PLAYSETS & ACCESSORIES</option>
        <option value="101">INTERLOCKING</option>
        <option value="102">MAGNETIC</option>
        <option value="103">MARBLE RUNS</option>
        <option value="121">BIN ITEMS</option>
        <option value="082">COLLECTIBLES</option>
        <option value="122">FIDGETS</option>
        <option value="123">GREETING CARDS</option>
        <option value="124">HEALTH & SAFETY</option>
        <option value="125">STICKERS</option>
        <option value="082">COLLECTIBLES</option>
        </select>

      </div>
      <div class="form-group">
        <label for="description">Product Description</label>
        <input type="text" class="form-control" id="description" onChange={(e)=>setDescription(e.target.value)}/>
      </div>
      <div class="form-group">
        <label for="clu">CLU</label>
        <input type="text" class="form-control" id="clu" onChange={(e)=>setClu(e.target.value)}/>
      </div>
      <div class="form-group">
        <label for="baseprice">Base Price</label>
        <input type="text" class="form-control" id="baseprice" placeholder="" onChange={(e)=>setBasePrice(e.target.value)}/>
      </div>       
            </div>
        </div>

      <button type="submit" class="btn btn-primary mr-2" onClick={newCatalog}>Add</button>
    </form>
    </div>
 
                  </div>
                </div>
              </div>*/}

<form class="item-form">
            <div class="card">
                <h2>Basic Information</h2>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>TYPE<span class="required">*</span></label>
                        <select class="form-control" id="type" onChange={selectType} >
                            <option value="SingleItem">Single</option>
                            <option value="Style">Style</option>
                        </select>
                    </div>                    
                    <div class="input-group">
                        <label>PRODUCT CODE<span class="required">*</span></label>
                        <input type="text" required placeholder="Enter code" onChange={(e)=>setProductCode(e.target.value)}/>
                    </div>  
                    <div class="input-group">
                        <label>PRIMARY VENDOR<span class="required">*</span></label>
                        <input type="text" id="primary_vendor" placeholder="Enter Primary Vendor" onChange={(e)=>setPrimaryVendor(e.target.value)}/>
                    </div>                                                        
                    {/* <div class="input-group">
                        <label>BRAND NAME</label>
                        <input type="text" placeholder="Enter brand"  onChange={(e)=>setBrandName(e.target.value)}/>
                    </div> */}
                </div>  
                <div class="form-item-row">
                    <div class="input-group full-width">
                        <label>DESCRIPTION<span class="required">*</span></label>
                        <input type="text" id="description" placeholder="Enter Description" onChange={(e)=>setDescription(e.target.value)}/>
                    </div> 
                </div>              
                <div class="form-item-row">
                    <div class="input-group">
                        <label>BASE PRICE<span class="required">*</span></label>
                        <input type="text" id="baseprice" placeholder="0.00" onChange={(e)=>setBasePrice(e.target.value)} />
                    </div>                      
                    <div class="input-group">
                        <label>CLU<span class="required">*</span></label>
                        <input type="text" id="clu" placeholder="Enter CLU" onChange={(e)=>setClu(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>UPC(NC)</label>
                        <input type="text" id="upc" placeholder="Enter UPC" onChange={(e)=>setUPC(e.target.value)}/>
                    </div>  
                                     
                    {/* <div class="input-group">
                        <label>PLU</label>
                        <input type="text" placeholder="Enter PLU"  onChange={(e)=>setPlu(e.target.value)} />
                    </div> */}

                    {/*<div class="input-group">
                        <label>EID</label>
                        <input type="text" placeholder="Enter EID" />
                    </div> */}
                </div>

            </div>

            <div class="card">
                <h2>Classification</h2>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>DEPARTMENT<span class="required">*</span></label>
                        <select class="form-control" id="store" onChange={selectDept}>
                            <option value="">Select Department</option>
                            <option value="001">ARTS & CRAFTS</option>
                            <option value="002">BOOKS</option>
                            <option value="004">ACTIVE PLAY & SKILL TOYS</option>
                            <option value="006">GAMES & PUZZLES</option>
                            <option value="007">DOLLS</option>
                            <option value="009">CONSTRUCTION & BUILDING</option>
                            <option value="011">IMPULSE</option>
                            <option value="012">PLUSH</option>
                            <option value="013">IMAGINATIVE & PRETEND PLAY</option>
                            <option value="014">SCIENCE</option>
                            <option value="015">TRANSIT</option>
                            <option value="017">TRADING CARDS</option>
                            <option value="018">FASHION</option>
                            <option value="019">DEVELOPMENTAL & EDUCATIONAL</option>
                            <option value="021">FOOD</option>
                            <option value="029">SERVICES</option>
                            <option value="030">SUPPLIES</option>
                            <option value="999">NEON ELEPHANT</option>
                        </select>
                    </div>
                    {/* <div class="input-group">
                        <label>ALT.DEPARTMENT</label>
                        <input type="text" placeholder="Enter alt department" onChange={(e)=>setAltDept(e.target.value)}/>
                    </div> */}
                    <div class="input-group">
                        <label>CLASS<span class="required">*</span></label>
                        <select class="form-control" id="selectDeptClassCode" onChange={selectClass}>
                            <option value="">Select Department</option>
                            <option value="001">ACTIVITY KITS</option>
                            <option value="002">ART SUPPLIES</option>
                            <option value="003">FASHION & JEWELRY KITS</option>
                            <option value="021">ACTIVITY BOOKS</option>
                            <option value="022">BOARD & CLOTH BOOKS</option>
                            <option value="023">CHAPTER & SERIES BOOKS</option>
                            <option value="024">LEARN TO READ BOOKS</option>
                            <option value="025">WORKBOOKS</option>
                            <option value="026">HARDCOVER</option>
                            <option value="027">PAPERBACK</option>
                            <option value="028">BABY & INDESTRUCTIBLES</option>
                            <option value="041">BUBBLES & CHALK</option>
                            <option value="042">INDOOR PLAY</option>
                            <option value="043">LAWN & GARDEN</option>
                            <option value="044">OUTDOOR PLAY</option>
                            <option value="045">POOL, WATER & SAND</option>
                            <option value="046">RIDE-ONS</option>
                            <option value="047">SKILL TOYS</option>
                            <option value="048">SPORTS</option>
                            <option value="061">BRAINTEASERS</option>
                            <option value="062">CARD GAMES</option>
                            <option value="063">ELECTRONIC </option>
                            <option value="064">FAMILY GAMES </option>
                            <option value="065">PUZZLES </option>
                            <option value="066">TABLETOP GAMES </option>
                            <option value="067">HANDHELD GAMES </option>
                            <option value="261">LEARNING AIDS </option>
                            <option value="081">BABY DOLLS & ACCESSORIES</option>
                            <option value="082">COLLECTIBLES</option>
                            <option value="083">FASHION DOLLS & ACCESSORIES</option>
                            <option value="084">DOLL HOUSES, PLAYSETS & ACCESSORIES</option>
                            <option value="101">INTERLOCKING</option>
                            <option value="102">MAGNETIC</option>
                            <option value="103">MARBLE RUNS</option>
                            <option value="121">BIN ITEMS</option>
                            <option value="082">COLLECTIBLES</option>
                            <option value="122">FIDGETS</option>
                            <option value="123">GREETING CARDS</option>
                            <option value="124">HEALTH & SAFETY</option>
                            <option value="125">STICKERS</option>
                            <option value="082">COLLECTIBLES</option>
                        </select>                    
                      </div>
                    <div class="input-group">
                        <label>TAX GROUP CODE<span class="required">*</span></label>
                        <input type="text" id="taxgroupcode" placeholder="Enter Tax Group Code" onChange={(e)=>setTaxGroupCode(e.target.value)}/>
                    </div>
                </div>
                <div class="form-item-row">
                    {/* <div class="input-group">
                        <label>DEPT CLASS CODE</label>
                        <input type="text" placeholder="Enter Dept Class Code" onChange={(e)=>setUPC(e.target.value)} />
                    </div> */}
                    {/* <div class="input-group">
                        <label>ALT. CLASS	</label>
                        <input type="text" placeholder="Enter Alt Class" onChange={(e)=>setAltClass(e.target.value)} />
                    </div> */}
                    <div class="input-group">
                        <label>SUB CLASS 1(NC)	</label>
                        <input type="text" placeholder="Enter SUB Class 1" onChange={(e)=>setSubClass1(e.target.value)} />
                    </div>
                </div>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>SUB CLASS 2(NC)</label>
                        <input type="text" placeholder="Enter SUB Class 1" onChange={(e)=>setSubClass2(e.target.value)} />
                    </div>
                    {/* <div class="input-group">
                        <label>ALT. SUB CLASS 1	</label>
                        <input type="text" placeholder="Enter ALT. SUB Class 1" onChange={(e)=>setAltSubClass1(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>ALT. SUB CLASS 2</label>
                        <input type="text" placeholder="Enter ALT. SUB Class 2" onChange={(e)=>setAltSubClass2(e.target.value)} />
                    </div> */}
                </div>                
            </div>

            <div class="card">
                <h2>Vendor Information</h2>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>VLU(NC)</label>
                        <input type="text" placeholder="Enter VLU" onChange={(e)=>setVlu(e.target.value)}/>
                    </div>
                    <div class="input-group">
                        <label>MIN.QTY</label>
                        <input type="number" placeholder="0" onChange={(e)=>setMinQty(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>ORDER COST</label>
                        <input type="number" step="0.01" placeholder="0.00" onChange={(e)=>setOrderCost(e.target.value)} />
                    </div>
                    {/* <div class="input-group">
                        <label>DATE AVAILABLE</label>
                        <input type="date" onChange={(e)=>setDateAvailable(e.target.value)} />
                    </div> */}
                </div>
            </div>

            {/* <div class="card">
                <h2>Dimensions</h2>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>HEIGHT</label>
                        <input type="number" step="0.01" placeholder="Enter Height" onChange={(e)=>setHeight(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>WIDTH</label>
                        <input type="number" step="0.01" placeholder="Enter Width" onChange={(e)=>setWidth(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>LENGTH</label>
                        <input type="number" step="0.01" placeholder="Enter Lenght" onChange={(e)=>setLength(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>WEIGHT</label>
                        <input type="number" step="0.01" placeholder="Enter Weight" onChange={(e)=>setWeight(e.target.value)} />
                    </div>
                </div>
            </div> */}

            {/* <div class="card">
                <h2>UPC Information</h2>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>VENDOR UPC</label>
                        <input type="text" placeholder="Enter vendor UPC" onChange={(e)=>setVendorUpc(e.target.value)}/>
                    </div>

                    <div class="input-group">
                        <label>UPC2</label>
                        <input type="text" placeholder="Enter UPC2" />
                    </div>
                </div>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>UPC3</label>
                        <input type="text" placeholder="Enter UPC3" />
                    </div>
                    <div class="input-group">
                        <label>UPC4</label>
                        <input type="text" placeholder="Enter UPC4" />
                    </div>
                    <div class="input-group">
                        <label>UPC5</label>
                        <input type="text" placeholder="Enter UPC5" />
                    </div>
                </div>
            </div> */}

            {/* <div class="card">
                <h2>Class Information</h2>

            </div> */}


            <div class="card">
                <h2>Attribute Information</h2>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>ATTRIBUTE 1(NC)</label>
                        <input type="text" placeholder="Enter Attribute 1" onChange={(e)=>setAttribute1(e.target.value)} />
                    </div> 
                    <div class="input-group">
                        <label>ATTRIBUTE 2(NC)</label>
                        <input type="text" placeholder="Enter Attribute 2" onChange={(e)=>setAttribute2(e.target.value)} />
                    </div>   
                    <div class="input-group">
                        <label>ATTRIBUTE 3(NC)</label>
                        <input type="text" placeholder="Enter Attribute 3" onChange={(e)=>setAttribute3(e.target.value)} />
                    </div>                                                        

                </div>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>ATTRIBUTE SET 1(NC)</label>
                        <input type="text" placeholder="Enter Attribute Set 1	" onChange={(e)=>setAttributeSet1(e.target.value)} />
                    </div>                    
                    <div class="input-group">
                        <label>ATTRIBUTE SET 2(NC)</label>
                        <input type="text" placeholder="Enter Attribute Set 2" onChange={(e)=>setAttributeSet2(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>ATTRIBUTE SET 3(NC)</label>
                        <input type="text" placeholder="Enter Attribute Set 3" onChange={(e)=>setAttributeSet3(e.target.value)} />
                    </div>
                </div>
            </div>

            {/* <div class="card">
                <h2>Price Information</h2>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>ELIGIBLE FOR DISCOUNT</label>
                        <select class="form-control" id="store" onChange={selectEligibleForDiscount}>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>
                    <div class="input-group">
                        <label>SHIP FROM ORDER</label>
                        <select class="form-control" id="store" onChange={selectShipFromVendor}>
                            <option value="Never">Never</option>
                            <option value="ok">Ok</option>
                            <option value="always">Always</option>
                        </select> 
                    </div>
                    <div class="input-group">
                        <label>SELL METHOD</label>
                        <select class="form-control" id="store" onChange={(e)=>setSellMethod(e.target.value)} >
                            <option value="">Select Sell Method</option>
                            <option value="Available">Available</option>
                        </select>                    
                    </div>
                    <div class="input-group">
                        <label>DISCONTINUED</label>
                        <select class="form-control" id="store" onChange={selectDiscontinued}>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>
                </div>

                <h2>Price Level</h2>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>PRICE LEVEL 2</label>
                        <input type="text" placeholder="Enter Price Level 2" onChange={(e)=>setPriceLevel2(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>PRICE LEVEL 3</label>
                        <input type="text" placeholder="Enter Price Level 3" onChange={(e)=>setPriceLevel3(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>PRICE LEVEL 4</label>
                        <input type="text" placeholder="Enter Price Level 4" onChange={(e)=>setPriceLevel4(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>PRICE LEVEL 5</label>
                        <input type="text" placeholder="Enter Price Level 5" onChange={(e)=>setPriceLevel5(e.target.value)} />
                    </div>
                </div>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>PRICE LEVEL 6</label>
                        <input type="text" placeholder="Enter Price Level 6" onChange={(e)=>setPriceLevel6(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>PRICE LEVEL 7</label>
                        <input type="text" placeholder="Enter Price Level 7" onChange={(e)=>setPriceLevel7(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>PRICE LEVEL 8</label>
                        <input type="text" placeholder="Enter Price Level 8" onChange={(e)=>setPriceLevel8(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>PRICE LEVEL 9</label>
                        <input type="text" placeholder="Enter Price Level 9" onChange={(e)=>setPriceLevel9(e.target.value)} />
                    </div>
                </div>                               
                <h2>Price </h2>

                <div class="form-item-row">
                    <div class="input-group">
                        <label>PRICE 2</label>
                        <input type="text" placeholder="Enter Price 2" onChange={(e)=>setPrice2(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>PRICE 3</label>
                        <input type="text" placeholder="Enter Price 3" onChange={(e)=>setPrice3(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>PRICE 4</label>
                        <input type="text" placeholder="Enter Price 4" onChange={(e)=>setPrice4(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>PRICE 5</label>
                        <input type="text" placeholder="Enter Price 5" onChange={(e)=>setPrice5(e.target.value)} />
                    </div>
                </div>

                <div class="form-item-row">
                    <div class="input-group">
                        <label>PRICE 6</label>
                        <input type="text" placeholder="Enter Price 6" onChange={(e)=>setPrice6(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>PRICE 7</label>
                        <input type="text" placeholder="Enter Price 7" onChange={(e)=>setPrice7(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>PRICE 8</label>
                        <input type="text" placeholder="Enter Price 8" onChange={(e)=>setPrice8(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>PRICE 9</label>
                        <input type="text" placeholder="Enter Price 9" onChange={(e)=>setPrice9(e.target.value)} />
                    </div>
                </div>                

            </div>

            <div class="card">
                <h2>Vendor Information</h2>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>ITEM CUSTOM NUM 1	</label>
                        <input type="text" placeholder="Enter Item Custom Num 1	" onChange={(e)=>setPrice5(e.target.value)} />
                    </div>
                </div>
                <div class="form-item-row">
                    <div class="input-group">
                        <label>CUSTOM TEXT 1</label>
                        <input type="text" placeholder="Enter Custom Text 1" onChange={(e)=>setCustomText1(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>CUSTOM LONG TEXT 1</label>
                        <input type="text" placeholder="Enter Custom Long Text 1" onChange={(e)=>setCustomLongText1(e.target.value)} />
                    </div>                  
                    <div class="input-group">
                        <label>CUSTOM LONG TEXT 2</label>
                        <input type="text" placeholder="Enter Custom Long Text 2" onChange={(e)=>setCustomLongText2(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>CUSTOM LONG TEXT 3</label>
                        <input type="text" placeholder="Enter Custom Long Text 3" onChange={(e)=>setCustomLongText3(e.target.value)} />
                    </div>
                </div>
                <h2>Custom Flag</h2>

                <div class="form-item-row">
                    <div class="input-group">
                        <label>ITEM CUSTOM FLAG 1	</label>
                        <select class="form-control" id="store" onChange={selectCustomFlag1}>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>
                    <div class="input-group">
                        <label>FLAG 1</label>
                        <select class="form-control" id="store" onChange={selectCustomFlag1}>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>                  
                    <div class="input-group">
                        <label>FLAG 2</label>
                        <select class="form-control" id="store" onChange={selectCustomFlag2}>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>
                    <div class="input-group">
                        <label>FLAG 3</label>
                        <select class="form-control" id="store" onChange={selectCustomFlag3}>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>
                </div>

                <div class="form-item-row">
                    <div class="input-group">
                        <label>FLAG 4</label>
                        <select class="form-control" id="store" onChange={selectCustomFlag4}>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>
                    <div class="input-group">
                        <label>FLAG 5</label>
                        <select class="form-control" id="store" onChange={selectCustomFlag5}>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>                  
                    <div class="input-group">
                        <label>FLAG 6</label>
                        <select class="form-control" id="store" onChange={selectCustomFlag6}>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>
                    <div class="input-group"></div>
                </div>

                <h2>Lookup</h2>

                <div class="form-item-row">
                    <div class="input-group">
                        <label>CUSTOM LOOKUP 1</label>
                        <input type="text" placeholder="Enter Custom Lookup 1" onChange={(e)=>setCustomLookup1(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>CUSTOM LOOKUP 2</label>
                        <input type="text" placeholder="Enter Custom Lookup 2" onChange={(e)=>setCustomLookup2(e.target.value)} />
                    </div>                  
                    <div class="input-group">
                        <label>CUSTOM LOOKUP 3</label>
                        <input type="text" placeholder="Enter Custom Lookup 3" onChange={(e)=>setCustomLookup3(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>CUSTOM LOOKUP 4	</label>
                        <input type="text" placeholder="Enter Custom Lookup 4" onChange={(e)=>setCustomLookup4(e.target.value)} />
                    </div>
                </div>

                <div class="form-item-row">
                    <div class="input-group">
                        <label>CUSTOM LOOKUP 5</label>
                        <input type="text" placeholder="Enter Custom Lookup 5" onChange={(e)=>setCustomLookup5(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>CUSTOM LOOKUP 6</label>
                        <input type="text" placeholder="Enter Custom Lookup 6" onChange={(e)=>setCustomLookup6(e.target.value)} />
                    </div>                  
                    <div class="input-group">
                        <label>CUSTOM LOOKUP 7</label>
                        <input type="text" placeholder="Enter Custom Lookup 7" onChange={(e)=>setCustomLookup7(e.target.value)} />
                    </div>
                    <div class="input-group">
                        <label>CUSTOM LOOKUP 8</label>
                        <input type="text" placeholder="Enter Custom Lookup 8" onChange={(e)=>setCustomLookup8(e.target.value)} />
                    </div>
                </div>
            </div>

            <div class="card">
                  <h2>Multi Select</h2>
                    <div class="form-item-row">
                        <div class="input-group">
                            <label>CUSTOM MULTISELECT 1</label>
                            <input type="text" placeholder="Enter Custom Multi Select 1" onChange={(e)=>setCustomMultiselects1(e.target.value)} />
                            </div>
                        <div class="input-group">
                            <label>CUSTOM MULTISELECT 2</label>
                            <input type="text" placeholder="Enter Custom Multi Select 2" onChange={(e)=>setCustomMultiselects2(e.target.value)} />
                            </div>
                        <div class="input-group">
                            <label>CUSTOM MULTISELECT 3</label>
                            <input type="text" placeholder="Enter Custom Multi Select 3" onChange={(e)=>setCustomMultiselects3(e.target.value)} />
                            </div>
                        <div class="input-group">

                          </div>
                    </div>
                </div> */}

                <button type="submit" className="submit-btn" onClick={newCatalog}>Save Item</button>

                </form>



            </div>
          </div> 
    </div>  
    <Footer />

</>
}
