export default function  Footer(){
    return (
        <>
            {/* <footer class="footer">
              <div class="footer-inner-wraper">
                <div class="d-sm-flex justify-content-center justify-content-sm-between">
                  <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © bootstrapdash.com 2020</span>
                  <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> <a href="https://ebramha.com/" target="_blank">Designed By eBramha Techworks Private Limited </a></span>
                </div>
              </div>
            </footer>         */}
        </>
    )
}