import React, { useState } from 'react'
import logo from '../../assets/images/le-logo.png';
import '../../assets/js/main.js'
import { API } from "../../helper/Api";
import { useNavigate } from 'react-router-dom';

function Login() {
  const [name, setName] = useState('');
  const [pwd, setPwd] = useState('');
  const [invalid, setInvalid] = useState('');
  const [errors, setErrors] = useState({
    name: '',
    pwd: ''
  });
  const navigate = useNavigate(); 
      // Hide error message when user starts typing
      const handleNameChange = (e) => {
        setName(e.target.value);
        if (errors.name) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: '', // Clear the error for name
          }));
        }
      };
    
      const handlePwdChange = (e) => {
        setPwd(e.target.value);
        if (errors.pwd) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            pwd: '', // Clear the error for pwd
          }));
        }
      }; 
  const checkUser = (e) => {
    e.preventDefault();  
     // Validate the fields
     let formErrors = { name: '', pwd: '' };
     let isValid = true;
 
     // Username validation
     if (name.trim() === '') {
       formErrors.name = 'Username is required';
       isValid = false;
     }
 
     // Password validation
     if (pwd.trim() === '') {
       formErrors.pwd = 'Password is required';
       isValid = false;
     }
 
     // Set errors state
     setErrors(formErrors);
     if (isValid) {
// API.getUser({name,pwd})
          // .then(result => console.log(result))
          // .catch(error => console.log('error', error));
          fetch('https://tw.ebramha.com/api/users.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',  // Set content type to JSON
            },
            body: JSON.stringify({
                username: name,
                password: pwd
            })
        })
        .then(response => response.json())
        .then(data => {
          //console.log(data.success)
          if(data.success == true){
            sessionStorage.setItem("userNames",data.userName);
            sessionStorage.setItem("sessionStartTime",data.sessionStartTime);
            sessionStorage.setItem("roles",data.storeCode);
            navigate('/');
          }
          else{
            setInvalid('Invalid credentials');
          }
           console.log("check->",data.message);
        })
        .catch(error => console.error('Error:', error));
     }
 
      
        
}


  return (
    <div>
 <div class="content">
 <style>{'body { background-color: #f8fafb; }'}</style>
    <div class="container mt-4">
      <div class="row justify-content-center p-4 m-4">
         {/* <div class="col-md-6 order-md-2">
          <img src="images/undraw_file_sync_ot38.svg" alt="Image" class="img-fluid" />
        </div>  */}
        <div class="col-md-6 contents">
          <div class="row justify-content-center" >
            <div class="col-md-12">
              <div class="form-block">
                  <div class="mb-4">
                  <img src={logo} alt="logo" class="img-fluid"  />
                  
                  {/* <p class="mb-4">Lorem ipsum dolor sit amet elit. Sapiente sit aut eos consectetur adipisicing.</p> */}
                </div>
                <form>
                {invalid && <div className="invalid">{invalid}</div>}
                  <div class="form-group first">
                    {/* <label for="username">Username</label> */}
                    <input type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`} placeholder='Username' name="name" id="username" required onChange={handleNameChange} />
                    {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                  </div>
                  <div class="form-group last mb-4">
                    {/* <label for="password">Password</label> */}
                    <input type="password" className={`form-control ${errors.pwd ? 'is-invalid' : ''}`} placeholder='Password' name="pwd" id="password" required onChange={handlePwdChange}/>
                    {errors.pwd && <div className="invalid-feedback">{errors.pwd}</div>}
                    
                  </div>
                  
                  <div class="d-flex mb-5 align-items-center">
                    {/* <label class="control control--checkbox mb-0"><span class="caption">Remember me</span>
                      <input type="checkbox" checked="checked"/>
                      <div class="control__indicator"></div>
                    </label> */}
                    
                    <span class="ml-auto"><a href="#" class="forgot-pass">Forgot Password</a></span> 
                  </div>

                  <input type="submit" value="Log In" onClick={checkUser} class="btn btn-pill text-white btn-block" />

                  {/* <span class="d-block text-center my-4 text-muted"> or sign in with</span> */}
                  
                </form>
              </div>
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  </div>

        
    </div>
  )
}

export default Login